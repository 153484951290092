import axios from 'axios';
import React, { Component, Fragment, Suspense, lazy } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

import AppURL from '../api/AppURL'

const Apply = React.lazy(() => import("../pages/Apply"))
const Partner = React.lazy(() => import("../pages/Partner"))

const Home = React.lazy(() => import("../pages/Home"))
const Gallery = React.lazy(() => import("../pages/Gallery"))
const Video = React.lazy(() => import("../pages/Video"))
const Clients = React.lazy(() => import("../pages/Clients"))
const Testimonials = React.lazy(() => import("../pages/Testimonials"))
const Blog = React.lazy(() => import("../pages/Blog"))
const LatestNews = React.lazy(() => import("../pages/LatestNews"))
const Downloads = React.lazy(() => import("../pages/Downloads"))
const Contact = React.lazy(() => import("../pages/Contact"))
const Faqs = React.lazy(() => import("../pages/Faqs"))
const BlogDetails = React.lazy(() => import("../pages/BlogDetails"))
const LatestNewsDetails = React.lazy(() => import("../pages/LatestNewsDetails"))
const Page = React.lazy(() => import("../pages/Page"))


const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
class AppRoute extends Component {

  constructor() {
    super();
    this.state = {
      user: {}
    }

  }

  componentDidMount() {

    axios.get(AppURL.UserData).then((response) => {
      this.setUser(response.data)

    }).catch(error => {

    });

  }

  setUser = (user) => {

    this.setState({ user: user })

  }


  render() {
    return (

      <Fragment>
        <Suspense>
          <Header />

          <Router basename={process.env.PUBLIC_URL} history={history}>

            <Route exact path='/' render={(props) => <Home {...props} key={Date.now()} />} />
            <Route exact path='/gallery/gallery' render={(props) => <Gallery {...props} key={Date.now()} />} />
            <Route exact path='/video/video' render={(props) => <Video {...props} key={Date.now()} />} />
            <Route exact path='/clients/clients' render={(props) => <Clients {...props} key={Date.now()} />} />
            <Route exact path='blog/blog' render={(props) => <Blog {...props} key={Date.now()} />} />
            <Route exact path='/testimonials/testimonials' render={(props) => <Testimonials {...props} key={Date.now()} />} />
            <Route exact path='/news/news' render={(props) => <LatestNews {...props} key={Date.now()} />} />
            <Route exact path='/downloads/downloads' render={(props) => <Downloads {...props} key={Date.now()} />} />
            <Route exact path='/contact/contact-us' render={(props) => <Contact {...props} key={Date.now()} />} />
            <Route exact path='/faqs/frequently-asked-questions' render={(props) => <Faqs {...props} key={Date.now()} />} />
            <Route exact path='/blog/:blog_url' render={(props) => <BlogDetails {...props} key={Date.now()} />} />
            <Route exact path='/news/:latestnews_url' render={(props) => <LatestNewsDetails {...props} key={Date.now()} />} />
            <Route exact path='/:page_url' render={(props) => <Page {...props} key={Date.now()} />} />
            <Route exact path='/apply/apply-now' render={(props) => <Apply {...props} key={Date.now()} />} />
            <Route exact path='/partner/partner-with-us' render={(props) => <Partner {...props} key={Date.now()} />} />
          </Router>
          <Footer />
        </Suspense>
      </Fragment>

    )
  }
}

export default AppRoute;
