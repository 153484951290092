import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';

const Favicon = React.lazy(() => import("./Favicon"))
const LogoWithAddress = React.lazy(() => import("./LogoWithAddress"))
const Logo = React.lazy(() => import("./Logo"))
const LogoWithNav = React.lazy(() => import("./LogoWithNav"))
const Menu = React.lazy(() => import("./Menu"))
const News = React.lazy(() => import("./News"))
const TopHeader = React.lazy(() => import("./TopHeader"))
const JavaScriptTop = React.lazy(() => import("./JavaScriptTop"))



class Header extends Component {
  componentDidMount() {
    window.scroll(0, 0)
  }

  render() {
    return (
      <Fragment>
        <Suspense>
          <Helmet>
            <JavaScriptTop />
          </Helmet>
          <Favicon />
          <News />
          <TopHeader />
          <LogoWithAddress />
          <Menu />

        </Suspense>
      </Fragment>

    )
  }
}

export default Header;