import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AppURL from '../../api/AppURL';
import axios from 'axios';

const ContactPosition = React.lazy(() => import("./ContactPosition"))
const CopyRights = React.lazy(() => import("./CopyRights"))
const Logo = React.lazy(() => import("./Logo"))
const ContactDetails = React.lazy(() => import("./ContactDetails"))
const Socialmedia = React.lazy(() => import("./Socialmedia"))
const FooterAddress = React.lazy(() => import("./FooterAddress"))
const Subscribe = React.lazy(() => import("./Subscribe"))
const JavaScriptBottom = React.lazy(() => import("./JavaScriptBottom"))
const ScrollToTop = React.lazy(() => import("./ScrollToTop"))
const ApplyPosition = React.lazy(() => import("./ApplyPosition"))

class Footer extends Component {
    constructor() {
        super();
        this.state = {
            about_text: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetHomeAbout).then(response => {

            let JsonData = (response.data['Home About'])[0];
            this.setState({
                about_text: JsonData['about_text']

            });


        }).catch(error => {

        });
    }
    render() {
        return (
            <Fragment>
                <Suspense>
                    <div className="footer">
                        <Container>
                            <Row>
                                <Col lg={4} md={4} xl={4} xxl={4} sm={6} xs={12}>
                                    <Row>
                                        <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                            <h4><Logo /></h4>
                                        </Col>
                                        <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                            <div className="text">

                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                            <div className="footer-social">
                                                <h5>Follow Us On:</h5>
                                                <Socialmedia />

                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={2} md={2} xl={2} xxl={2} sm={6} xs={12}>
                                    <h4>Quick Links</h4>
                                    <ul>
                                        <li><a href={process.env.PUBLIC_URL + '/'}><span className="fa fa-angle-double-right"></span>Home</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/gallery/gallery'}><span className="fa fa-angle-double-right"></span>Photo Gallery</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/video/video'}><span className="fa fa-angle-double-right"></span>Video Gallery</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/clients/clients'}><span className="fa fa-angle-double-right"></span>Clients</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/testimonials/testimonials'}><span className="fa fa-angle-double-right"></span>Testimonials</a></li>
                                    </ul>

                                </Col>
                                <Col lg={2} md={2} xl={2} xxl={2} sm={6} xs={12}>
                                    <h4>Quick Links</h4>
                                    <ul>
                                        <li><a href={process.env.PUBLIC_URL + '/blog/blog'}><span className="fa fa-angle-double-right"></span>Blog</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/news/news'}><span className="fa fa-angle-double-right"></span>News</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/contact/contact-us'}><span className="fa fa-angle-double-right"></span>Contact Us</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/faqs/frequently-asked-questions'}><span className="fa fa-angle-double-right"></span>Faqs</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/downloads/downloads'}><span className="fa fa-angle-double-right"></span>Downloads</a></li>
                                    </ul>
                                </Col>

                                <Col lg={4} md={4} xl={4} xxl={4} sm={6} xs={12}>

                                    <h4>Contact Us</h4>
                                    <FooterAddress />


                                </Col>

                            </Row>

                        </Container >

                    </div >

                    <CopyRights />
                    <ContactPosition />
                    <ScrollToTop />
                    <JavaScriptBottom />
                </Suspense>
            </Fragment >


        )
    }
}

export default Footer;	